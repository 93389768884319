table {
  box-shadow: 3px 5px 20px rgba(0, 0, 0, 0.1);
  background-color: var(--white-color);
  min-width: 900px;
  width: 100%;
  border-collapse: collapse;
  margin-inline: auto;
  box-shadow: 1px 2px 6px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  /* overflow: auto; */
}
thead {
  background-color: var(--primary-color);
}
thead > tr {
  border: none;
}
tr {
  border: 1px solid rgba(33, 63, 125, 0.3);
}
tbody tr:nth-of-type(even) {
  background-color: #d2ddee;
}
tbody tr:nth-of-type(odd) {
  background-color: #e9eef6;
}
th,
td {
  padding: 10px 15px;
  color: var(--primary-color);
  border: 1px solid rgba(33, 63, 125, 0.3);
  position: relative;
  /* white-space: nowrap; */
}

th {
  color: var(--white-color);
  font-size: var(--fs-400);
  font-weight: 500;
}
td {
  font-size: var(--fs-200);
  position: relative;
}

td > button {
  outline: none;
  border: 1px solid black;

  padding: 5px;
  border-radius: 2px;
  cursor: pointer;
}

.table_header {
  display: flex;
  align-items: center;
  gap: 10px;
}

.table__page-container {
  max-width: 100%;
  width: fit-content;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-inline: auto;
}
.no_data {
  width: 100%;
}

.pagination_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 20px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.paginationBttns {
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 500;
  cursor: pointer;
  font-size: 14px;
  list-style-type: none;
  padding: 5px;
  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 2px;

  @media (min-width: 768px) {
    gap: 10px;
    font-size: 16px;
  }
}

.paginationActive {
  color: blue;
  font-weight: bold;
}

.prev,
.next {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;

  @media (min-width: 768px) {
    font-size: 20px;
  }
}

.break-me {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;

  @media (min-width: 768px) {
    font-size: 16px;
  }
}

svg {
  font-weight: 500;
  cursor: pointer;
  font-size: 20px;
}

.page_count {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    font-size: 16px;
    margin-bottom: 0;
  }
}
